
import NoneContent from '@/components/noneContent.vue'
import orderApi from '@/http/apis/order'
import { defineComponent, computed, reactive, toRefs, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
import { QuotationDetailsRes, GetQuoteOfflineDetailRes } from '@/types/responseType'
export default defineComponent({
  name: 'QuoteInfo',
  components: {
    NoneContent,
  },
  setup() {
    const route = useRoute()
    const billno = route.params.billno as string
    const state = reactive({
      isScroll: false,
      quote: {} as GetQuoteOfflineDetailRes & {
        mDate: string
      },
      detailList: [] as Array<QuotationDetailsRes>,
    })
    // 报价单总计
    const total = computed(() => {
      let total: number | string = 0
      for (let index = 0; index < state.detailList.length; index++) {
        const el = state.detailList[index]
        if (el.totalPrice === '***') {
          total = '***'
          break
        } else {
          ;(total as number) += Number(el.totalPrice)
        }
      }
      total !== '***' && (total = Number((total as number).toFixed(2)))
      return total
    })
    // 获取报价单详情
    const getInfo = async () => {
      const { data } = await orderApi.getQuotationOfflineDetail({
        quotationNumber: billno,
      })
      let mDate = '/'
      if (
        data.quotationInfo.finishTime &&
        data.quotationInfo.finishTime !== '0000-00-00 00:00:00' &&
        data.quotationInfo.finishTime !== '1970-01-01 08:00:00'
      ) {
        // 小于10补全位数
        const padStart = (string: string, targetLength: number, padString: string) => {
          while (string.length < targetLength) {
            string = padString + string
          }
          return string
        }
        let _date: Date | number = new Date(data.quotationInfo.finishTime.replace(/-/g, '/'))
        _date = _date.setDate(_date.getDate() + 30)
        const a = new Date(_date)
        const y = a.getFullYear()
        const m = padStart((a.getMonth() + 1).toString(), 2, '0')
        const d = padStart(a.getDate().toString(), 2, '0')
        const h = padStart(a.getHours().toString(), 2, '0')
        const mm = padStart(a.getMinutes().toString(), 2, '0')
        const s = padStart(a.getSeconds().toString(), 2, '0')
        mDate = `${y}-${m}-${d} ${h}:${mm}:${s}`
      }
      state.quote = data.quotationInfo
      state.quote.mDate = mDate
      state.detailList = data.quotationDetails
    }
    // 复制报价单
    const copy = () => {
      const input = document.createElement('input')
      input.value = state.quote.quotationNumber
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      Toast.success('复制成功')
    }

    onMounted(() => {
      getInfo()
    })

    return {
      ...toRefs(state),
      total,
      copy,
    }
  },
})
